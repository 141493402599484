export default {
    namespaced: true,
    state: {
        countryData: null,
        questions: [],
        questionCount: 2,
        questionIndex: 0,
        showResults: null,
        showResultOptions: [],
        started: false
    },
    getters: {
        answeredCount(state, getters) {
            return getters.answeredQuestions.length;
        },
        answersFalse(state, getters) {
            return getters.answeredQuestions.filter(question => question.answerCorrect === false).length;
        },
        answersFalsePercentage(state, getters) {
            return getters.answersFalse / getters.answeredCount * 100;
        },
        answersFalsePercentageFloor(state, getters) {
            return Math.floor(getters.answersFalsePercentage);
        },
        answeredQuestions(state) {
            return state.questions.filter(question => typeof question.answerCorrect === "boolean");
        },
        answersTrue(state, getters) {
            return getters.answeredQuestions.filter(question => question.answerCorrect === true).length;
        },
        answersTruePercentage(state, getters) {
            return getters.answersTrue / getters.answeredCount * 100;
        },
        answersTruePercentageFloor(state, getters) {
            return Math.floor(getters.answersTruePercentage);
        },
        currentQuestion(state) {
            return state.questions[state.questionIndex]
        },
        currentQuestionAnswer(state, getters) {
            return getters.currentQuestion.answer;
        },
        finished(state) {
            return state.questionIndex === state.questionCount;
        },
        lastQuestion(state) {
            return state.questionIndex + 1 === state.questionCount;
        },
        showResults(state) {
            if (state.showResults === null) {
                return [];
            }

            if (state.showResults.key === 'all') {
                return state.questions;
            } else if (state.showResults.key === 'onlyCorrect') {
                return state.questions.filter(question => question.answerCorrect === true);
            } else if (state.showResults.key === 'correctNot100') {
                return state.questions.filter(question => question.answerCorrect === true && question.similarity < 100);
            } else if (state.showResults.key === 'incorrect') {
                return state.questions.filter(question => question.answerCorrect === false);
            }
        }
    },
    mutations: {
        setAnswer(state, answer) {
            state.questions[state.questionIndex].answer = answer;
        },
        setAnswerCorrect(state) {
            state.questions[state.questionIndex].answerCorrect = state.questions[state.questionIndex].similarity >= 70;
        },
        setCountryData(state, countryData) {
            state.countryData = countryData;
        },
        setNextQuestion(state) {
            state.questionIndex++;
        },
        setQuestionCount(state, e) {
            state.questionCount = parseInt(e.target.value);
        },
        setShowResults(state, showResults) {
            state.showResults = showResults;
        },
        setSimilarity(state, similarity) {
            state.questions[state.questionIndex].similarity = Math.floor(similarity * 100);
        }
    },
    actions: {

    }
}