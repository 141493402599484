import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state: {
        allData: null,
        chosenContinentsCountries: [],
        continents: [],
        questionAll: false,
        questionsType: 'selection',
        quizModus: 'capitalByCountry',
        selectionOptionsCount: 4
    },
    getters: {
        continents(state) {
            let continents = [];
            if (state.allData) {
                state.allData.filter(country => {
                    country.continents.forEach(function (continent) {
                        if (!continents.includes(continent)) {
                            continents.push(continent);
                        }
                    })
                })

                return continents.sort();
            }

            return [];
        }
    },
    mutations: {
        setQuestionsType(state, questionsType) {
            state.questionsType = questionsType;
        },
        setQuizModus(state, quizModus) {
            state.quizModus = quizModus;
        },
        setSelectionOptionsCount(state, e) {
            let selectionOptionsCount = parseInt(e.target.value)
            if (selectionOptionsCount > 10) {
                selectionOptionsCount = 10;
            } else if (selectionOptionsCount < 2) {
                selectionOptionsCount = 2;
            }

            state.selectionOptionsCount = selectionOptionsCount;
        },
        toggleContinent(state, continent) {
            if (state.continents.includes(continent)) {
                state.continents.splice(state.continents.indexOf(continent), 1);
            } else {
                state.continents.push(continent);
            }
        },
        toggleQuestionAll(state) {
            state.questionAll = !state.questionAll;
        }
    },
    actions: {
        getAllData({state}) {
            axios.get('/json/allData.json').then(response => {
                let myJson = {};
                response.data.forEach((data) => {
                    myJson[data.cioc] = {
                        name: data.translations.deu.common,
                        capital: data.capital
                    };

                    data.answer = false;
                });

                state.allData = response.data.filter(country => country.independent === true);
            })
        },
        start({rootState, state}) {
            state.chosenContinentsCountries = [];
            state.allData.filter(country => {
                country.continents.forEach((continent) => {
                    if (state.continents.includes(continent)) {
                        state.chosenContinentsCountries.push(country);
                    }
                });
            });

            if (state.questionAll) {
                rootState.Quiz.questionCount = state.chosenContinentsCountries.length;
            }

            const countriesShuffled = _.shuffle(state.chosenContinentsCountries);
            rootState.Quiz.questions = countriesShuffled.slice(0, rootState.Quiz.questionCount);

            if (state.questionsType === 'selection') {
                rootState.Quiz.showResultOptions = [
                    {
                        text: 'All',
                        key: 'all',
                        btnClass: 'primary'
                    },
                    {
                        text: 'Correct Answers',
                        key: 'onlyCorrect',
                        btnClass: 'success'
                    },
                    {
                        text: 'Incorrect Answers',
                        key: 'incorrect',
                        btnClass: 'danger'
                    }
                ];
            } else if (state.questionsType === 'input') {
                rootState.Quiz.showResultOptions = [
                    {
                        text: 'All',
                        key: 'all',
                        btnClass: 'primary'
                    },
                    {
                        text: 'Correct Answers',
                        key: 'onlyCorrect',
                        btnClass: 'success'
                    },
                    {
                        text: 'Correct < 100%',
                        key: 'correctNot100',
                        btnClass: 'success'
                    },
                    {
                        text: 'Incorrect Answers',
                        key: 'incorrect',
                        btnClass: 'danger'
                    }
                ];
            }

            rootState.Quiz.started = true;
        }
    }
}