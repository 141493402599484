<template>
    <div class="col-12">
        <button class="btn w-100 mb-3" :class="continentActive" @click="toggleContinent(continent)">{{ $t("continent." + continent) }} ({{ countryCount }})</button>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
    name: 'SelectContinent',
    props: {
        continent: String
    },
    computed: {
        ...mapGetters('Settings', ['continents']),
        ...mapState('Settings', ['allData', 'continents']),
        continentActive() {
            return this.continents.includes(this.continent) ? ['bg-secondary', 'text-light'] : 'btn-outline-secondary';
        },
        countryCount() {
            let countries = this.allData.filter(country => country.continents.includes(this.continent));
            return countries.length;
        }
    },
    methods: {
        ...mapMutations('Settings', ['toggleContinent'])
    }
}
</script>

<style scoped lang="less">
button {

}
</style>