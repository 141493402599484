export default {
    computed: {
        askingQuestionTranslated() {
            if (this.quizModus === 'capitalByCountry' || this.quizModus === 'continentByCountry' || this.quizModus === 'flagByCountry') {
                return this.$t("country." + this.question.cioc + ".name");
            } else if (this.quizModus === 'countryByCapital') {
                return this.$t("country." + this.question.cioc + ".capital");
            }
        },
        askingWords() {
            return this.$t("question." + this.quizModus);
        }
    },
    methods: {
        getTranslatedData(question, modus) {
            if (modus === 'capitalByCountry') {
                return this.$t("country." + question.cioc + ".capital");
            } else if (modus === 'countryByCapital' || modus === 'countryByFlag') {
                return this.$t("country." + question.cioc + ".name");
            } else if (modus === 'continentByCountry') {
                return this.$t("continent." + question.continents[0]);
            } else if (modus === 'flagByCountry') {
                return question.flags.svg;
            }
        }
    }
}