<template>
    <div class="card">
        <div class="card-header inverse">
            {{ $t("settings.continents.title") }}
        </div>
        <div class="card-body">
            <div class="row">
                <select-continent v-for="continent in continents" :continent="continent"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectContinent from "@/components/Settings/SelectContinents/SelectContinent";

export default {
    name: 'SelectContinents',
    components: {SelectContinent},
    computed: {
        ...mapGetters('Settings', ['continents'])
    },
    created() {
        this.getAllData()
    },
    methods: {
        ...mapActions('Settings', ['getAllData'])
    }
}
</script>