<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="countryDataOffCanvas">
        <div class="offcanvas-body" v-if="countryData">
            <div class="row country-row p-3 mb-3">
                <div class="offset-2 col-8 name">
                    {{ countryData.name.common }}<br>
                    <small class="capital">{{ countryData.capital.join(',') }}</small>
                </div>
                <div class="col-2 text-end">
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            </div>
            <div class="row flag-row mb-3">
                <div class="col">
                    <img :src="countryData.flags.svg" class="flag">
                </div>
            </div>
            <div class="accordion" id="countryDataAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataContinents" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Continent
                        </button>
                    </h2>
                    <div id="countryDataContinents" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li class="list-group-item p-2" v-for="continent in countryData.continents">
                                    {{ continent }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataLanguage" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Languages
                        </button>
                    </h2>
                    <div id="countryDataLanguage" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li class="list-group-item" v-for="(language, lang) in countryData.languages">
                                    {{ language }} ({{ lang }})
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataPopulation" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Population
                        </button>
                    </h2>
                    <div id="countryDataPopulation" class="accordion-collapse collapse">
                        <div class="accordion-body p-2">
                            {{countryData.population }}
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataCurrencies" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Currencies
                        </button>
                    </h2>
                    <div id="countryDataCurrencies" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li v-for="currency in countryData.currencies" class="list-group-item">
                                    {{ currency.name }} {{ currency.symbol }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataMaps" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Maps
                        </button>
                    </h2>
                    <div id="countryDataMaps" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li v-for="(map, type) in countryData.maps" class="list-group-item">
                                    <a :href="map" target="_blank">{{ type }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataTimeZones" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Time Zones
                        </button>
                    </h2>
                    <div id="countryDataTimeZones" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li v-for="timeZone in countryData.timezones" class="list-group-item">
                                    {{ timeZone }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#countryDataTranslations" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                            Translations
                        </button>
                    </h2>
                    <div id="countryDataTranslations" class="accordion-collapse collapse">
                        <div class="accordion-body p-0">
                            <ul class="list-group">
                                <li class="list-group-item" v-for="(translation, lang) in countryData.translations">
                                    {{ translation.common }} ({{ lang }})
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: 'CountryData',
    computed: {
        ...mapState('Quiz', ['countryData'])
    }
}
</script>

<style scoped lang="less">
#countryDataOffCanvas {
    overflow: hidden;
    .offcanvas-body {
        overflow-x: hidden;
        padding: 0;
        .accordion-item {
            border-radius: 0 !important;
            .accordion-body {
                border-radius: 0 !important;
            }
        }
        .country-row {
            border-bottom: 1px solid #ccc;
            .name {
                font-size: 20px;
                font-weight: bold;
                small.capital {
                    color: #aaa;
                }
            }
        }
        .flag-row {
            .flag {
                max-width: 150px;
                border: 2px solid #aaa;
                border-radius: 100px;
            }
        }
    }
}
</style>