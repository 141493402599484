<template>
    <div class="card">
        <div class="card-header inverse">
            {{ $t("settings.questionsType.title") }}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="form-floating">
                        <select class="form-control" v-model="type">
                            <option value="selection">{{ $t("settings.questionsType.select.selection") }}</option>
                            <option value="input">{{ $t("settings.questionsType.select.inputField") }}</option>
                        </select>
                        <label for="questionsType">{{ $t("settings.questionsType.select.label") }}</label>
                    </div>
                </div>
                <div v-if="questionsType === 'selection'" class="col-12">
                    <div class="input-group mb-3">
                        <div class="form-floating">
                            <input type="number" class="form-control" :value="selectionOptionsCount" @input="setSelectionOptionsCount" min="2" max="10">
                            <label for="selectionOptionsCount">{{ $t("settings.questionsType.selection.label") }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: 'QuestionType',
    components: {},
    data() {
        return {
            type: null
        }
    },
    watch: {
        type(type) {
            this.setQuestionsType(type);
        }
    },
    computed: {
        ...mapState('Settings', ['questionsType', 'selectionOptionsCount'])
    },
    mounted() {
        this.type = this.questionsType;
    },
    methods: {
        ...mapMutations('Settings', ['setQuestionsType', 'setSelectionOptionsCount'])
    }
}
</script>