<template>
    <li class="nav-item">
        <a class="nav-link" :class="btnClasses" aria-current="page" href="javascript:" @click="setShowResults(showResultOption)">{{ showResultOption.text }}</a>
    </li>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: 'ShowResultButton',
    props: {
        showResultOption: Object
    },
    components: {},
    computed: {
        ...mapState('Quiz', ['showResults']),
        btnClasses() {
            let btnClasses = []
            if (this.showResults.key === this.showResultOption.key) {
                btnClasses.push('active');
            }

            return btnClasses;
        }
    },
    methods: {
        ...mapMutations('Quiz', ['setShowResults']),
    }
}
</script>

<style scoped lang="less">
.nav-item {
    margin-right: 20px;
}
.nav-item a {
    background-color: #6a8bad;
    color: #fff;
    font-weight: bold;
    &:hover, &.active {
        background-color: #2c3e50 !important;
        color: #fff !important;
    }
}
</style>