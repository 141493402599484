<template>
    <div class="progress w-100" v-if="answeredCount">
        <div class="progress-bar bg-success" :style="'width: ' + answersTruePercentage + '%'">{{ answersTruePercentageFloor }}%</div>
        <div class="progress-bar bg-danger" :style="'width: ' + answersFalsePercentage + '%'">{{ answersFalsePercentageFloor }}%</div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'PercentageBar',
    computed: {
        ...mapGetters('Quiz', ['answeredCount', 'answersTruePercentage', 'answersTruePercentageFloor', 'answersFalsePercentage', 'answersFalsePercentageFloor']),
        answerCorrect() {
            return this.currentQuestion.Country.answerCorrect;
        }
    },
}
</script>

<style scoped lang="less">
.progress {
    height: 30px;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
</style>