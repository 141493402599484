<template>
    <div class="card-body">
        <div class="alert alert-warning" v-if="error !== null">
            <span>{{ error.message }}</span>
        </div>
        <div class="form-floating mb-3">
            <input type="password" v-model="passwordNew" class="form-control" id="password" placeholder="New Password" required>
            <label for="password">New Password</label>
        </div>
        <button class="btn btn-secondary w-100" @click="setNewPassword({password: passwordNew})">Set New Password</button>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import CognitoLogin from "@/components/Cognito/Login";

export default {
    name: "CognitoSetNewPassword",
    components: {CognitoLogin},
    data() {
        return {
            passwordNew: ''
        };
    },
    computed: {
        ...mapState('Authentication', ['error', 'errorNewPasswordRequiredAttributes'])
    },
    methods: {
        ...mapActions('Authentication', ['setNewPassword'])
    }
};
</script>