import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import {CognitoIdentityCredentials, Config} from "aws-sdk";

import {cognitoPoolConfig} from "@/config";

export default {
    namespaced: true,
    state: {
        cognitoUser: null,
        config: null,
        error: null,
        errorNewPasswordRequiredAttributes: false,
        userPool: null
    },
    getters: {
        currentUser(state) {
            return state.userPool.getCurrentUser();
        },
        currentUserSession(state, getters) {
            if (!getters.currentUser) {
                return false;
            }

            return getters.currentUser.getSession(function(err, session) {
                return {
                    err: err,
                    session: session
                };
            })
        },
        currentUserPayload(state, getters) {
            if (!getters.currentUser) {
                return false;
            }

            return getters.currentUser.signInUserSession.idToken.payload;
        },
        isAuthenticated(state, getters) {
            if (getters.currentUser != null) {
                return getters.currentUser.getSession((err, session) => {
                    if (err) {
                        return false;
                    }
                    return session;
                })
            } else {
                return false;
            }
        }
    },
    actions: {
        init({state}) {
            state.config = cognitoPoolConfig;

            state.userPool = new CognitoUserPool({
                UserPoolId: state.config.UserPoolId,
                ClientId: state.config.ClientId
            })

            Config.region = cognitoPoolConfig.region;
        },
        login({state}, {username, password}) {
            let authenticationData = { Username: username, Password: password };
            let authenticationDetails = new AuthenticationDetails(authenticationData);
            let userData = { Username: username, Pool: state.userPool };
            let cognitoUser = new CognitoUser(userData);

            state.error = null;

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    //console.log('access token + ' + result.getAccessToken().getJwtToken())
                    var logins = {}
                    logins['cognito-idp.' + state.config.region + '.amazonaws.com/' + state.config.UserPoolId] = result.getIdToken().getJwtToken()

                    Config.credentials = new CognitoIdentityCredentials({
                        IdentityPoolId: state.config.UserPoolId,
                        Logins: logins
                    })

                    window.location.reload();
                },
                onFailure: function (error) {
                    state.error = error;
                },
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    state.cognitoUser = cognitoUser;
                    state.errorNewPasswordRequiredAttributes = requiredAttributes;
                    state.error = {
                        message: 'New Password Is Required'
                    };
                }
            })
        },
        logout({state}) {
            state.userPool.getCurrentUser().signOut(() => window.location.reload());
        },
        setNewPassword({state}, {password}) {
            state.error = false;
            state.cognitoUser.completeNewPasswordChallenge(password, state.errorNewPasswordRequiredAttributes, {
                onSuccess: () => {
                    window.location.reload();
                },
                onFailure: (error) => {
                    state.error = error;
                }
            })
        }
    }
}