<template>
    <hr v-if="showButton">
    <div class="row mt-3" v-if="showButton">
        <div class="col">
            <button class="btn btn-secondary" @click="start()">{{ $t("settings.startQuiz.button") }}</button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: 'StartButton',
    computed: {
        ...mapState('Quiz', ['questionCount']),
        ...mapState('Settings', ['continents', 'quizModus']),
        showButton() {
            if (this.quizModus === 'continentByCountry') {
                return this.continents.length > 1 && this.questionCount > 0;
            }

            return this.continents.length && this.questionCount > 0;
        }
    },
    methods: {
        ...mapActions('Settings', ['start'])
    }
}
</script>

<style scoped lang="less">
button {
    border-radius: 10px;
    font-weight: 800;
}
</style>