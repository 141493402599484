<template>
    <div class="card-body" >
        <div class="alert alert-warning" v-if="error !== null">
            <span>{{ error.message }}</span>
        </div>
        <div class="form-floating mb-3">
            <input type="email" v-model="username" class="form-control" id="floatingInput" placeholder="Email Adress" required @keyup.enter="login({username: username, password: password})">
            <label for="floatingInput">Email address</label>
        </div>
        <div class="form-floating mb-3">
            <input type="password" v-model="password" class="form-control" id="password" placeholder="Password" required @keyup.enter="login({username: username, password: password})">
            <label for="password">Password</label>
        </div>
        <button class="btn btn-secondary w-100" @click="login({username: username, password: password})">Login</button>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
    name: "CognitoLogin",
    data() {
        return {
            username: '',
            password: ''
        };
    },
    computed: {
        ...mapState('Authentication', ['error'])
    },
    methods: {
        ...mapActions('Authentication', ['login'])
    }
};
</script>