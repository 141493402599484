<template>
    <div class="card shadow-lg">
        <div class="card-header inverse">
            <small>{{ askingWords }}</small>
            <br>
            <span v-if="quizModus === 'countryByFlag'"><img :src="question.flags.svg"></span>
            <span v-else>{{ askingQuestionTranslated }}</span>
        </div>
        <div class="card-body">
            <div v-if="questionsType === 'selection' || quizModus === 'continentByCountry'" class="row">
                <div class="col-12" v-for="selectAnswer in answersSelection">
                    <button class="btn btn-outline-secondary w-100 mb-3" @click="answer = selectAnswer; submitAnswer();">
                        <template v-if="quizModus === 'flagByCountry'">
                            <img :src="selectAnswer">
                        </template>
                        <template v-else>
                            {{ selectAnswer }}
                        </template>
                    </button>
                </div>
            </div>
            <div v-else-if="questionsType === 'input'" class="input-group">
                <div class="form-floating">
                    <input type="email" class="form-control" id="yourAnswer" :placeholder="$t('main.yourAnswer')" v-model="answer" @keyup.enter="submitAnswer">
                    <label for="floatingInput">{{ $t("main.yourAnswer") }}</label>
                </div>
                <button v-if="answer.length >= 2" class="btn btn-success" @click="submitAnswer">{{ $t("main.submit") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import TranslationsMixin from '@/mixins/translations';
import _ from "lodash";

export default {
    name: 'InputAnswer',
    props: {
        question: Object
    },
    components: {},
    mixins: [TranslationsMixin],
    data() {
        return {
            answer: ''
        }
    },
    mounted() {
        if (this.questionsType === 'input') {
            document.getElementById('yourAnswer').focus();
        }
    },
    computed: {
        ...mapState('Settings', ['chosenContinentsCountries', 'continents', 'questionsType', 'quizModus', 'selectionOptionsCount']),
        answerCorrect() {
            return this.currentQuestion.Country.answerCorrect;
        },
        answersSelection() {
            if (this.quizModus === 'continentByCountry') {
                let translatedContinents = [];
                this.continents.forEach((continent) => {
                    translatedContinents.push(this.$t("continent." + continent));
                });

                return translatedContinents;
            }

            let selection = this.similarAnswers.slice(0, this.selectionOptionsCount - 1);
            selection.push(this.correctAnswer);

            return _.shuffle(selection);
        },
        correctAnswer() {
            return this.getTranslatedData(this.question, this.quizModus);
        },
        similarAnswers() {
            let translatedAnswers = [];
            this.chosenContinentsCountries.forEach((country) => {
                if (this.quizModus === 'capitalByCountry') {
                    translatedAnswers.push(this.$t("country." + country.cioc + ".capital"));
                } else if (this.quizModus === 'countryByCapital' || this.quizModus === 'countryByFlag') {
                    translatedAnswers.push(this.$t("country." + country.cioc + ".name"));
                } else if (this.quizModus === 'flagByCountry') {
                    translatedAnswers.push(country.flags.svg);
                }
            });

            let similarAnswers = [];
            let similarity = 99;
            while (similarAnswers.length < (this.selectionOptionsCount - 1) && similarity > 0) {
                translatedAnswers.forEach((capital) => {
                    let check = Math.floor(this.similarity(capital, this.correctAnswer) * 100);
                    if (capital !== this.correctAnswer && !similarAnswers.includes(capital) && check >= similarity) {
                        similarAnswers.push(capital);
                    }
                });

                similarity = similarity - 5;
            }

            return _.shuffle(similarAnswers);
        }
    },
    methods: {
        ...mapMutations('Quiz', ['setAnswer', 'setAnswerCorrect', 'setSimilarity']),
        submitAnswer() {
            if (this.answer.length < 2) {
                return;
            }

            this.submitted = true;
            this.setAnswer(this.answer);

            const similarity = this.similarity(this.answer, this.correctAnswer);

            this.setSimilarity(similarity);
            this.setAnswerCorrect();
        },
        editDistance(s1, s2) {
            s1 = s1.toLowerCase();
            s2 = s2.toLowerCase();

            let costs = [];
            for (let i = 0; i <= s1.length; i++) {
                let lastValue = i;
                for (let j = 0; j <= s2.length; j++) {
                    if (i === 0)
                        costs[j] = j;
                    else {
                        if (j > 0) {
                            let newValue = costs[j - 1];
                            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                                newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                            }
                            costs[j - 1] = lastValue;
                            lastValue = newValue;
                        }
                    }
                }
                if (i > 0) {
                    costs[s2.length] = lastValue;
                }
            }
            return costs[s2.length];
        },
        similarity(s1, s2) {
            let longer = s1;
            let shorter = s2;
            if (s1.length < s2.length) {
                longer = s2;
                shorter = s1;
            }
            let longerLength = longer.length;
            if (longerLength === 0) {
                return 1.0;
            }
            return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
        }
    }
}
</script>

<style scoped lang="less">
.input-group {
    padding: 10px;
}

.card-header {
    img {
        margin-top: 10px;
        width: 70px;
        border: 1px solid #000;
    }
}

.card-body {
    img {
        width: 70px;
        border: 1px solid #000;
    }
}
</style>