import axios from "axios";

export default {
    namespaced: true,
    state: {
        language: "de",
        all: null
    },
    getters: {
        translations(state) {
            return state.all ? state.all.language : '';
        }
    },
    actions: {
        init({state}) {
            axios.get('/json/translations.json').then(response => {
                state.all = response.data;
            })
        }
    }
}