<template>
    <ul class="nav nav-tabs">
        <show-result-button v-for="showResultOption in showResultOptions" :showResultOption="showResultOption" />
    </ul>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import ShowResultButton from "@/components/Results/ShowResultButton";

export default {
    name: 'ShowResultButtonGroup',
    components: {ShowResultButton},
    created() {
        this.setShowResults(this.showResultOptions[0]);
    },
    computed: {
        ...mapState('Quiz', ['showResultOptions'])
    },
    methods: {
        ...mapMutations('Quiz', ['setShowResults']),
    }
}
</script>

<style scoped lang="less">

</style>