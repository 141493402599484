<template>
    <div class="container">
        <div class="row mb-5">
            <div class="col-xs-12 offset-lg-3 col-lg-6">
                <input-answer v-if="currentQuestionAnswer === false" :question="currentQuestion"/>
                <solution v-else :question="currentQuestion" :showNextButton="true"/>
                <percentage-bar/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import PercentageBar from "@/components/Plugin/PercentageBar";
import InputAnswer from "@/components/Question/InputAnswer";
import Solution from "@/components/Question/Solution";

export default {
    name: 'Questions',
    components: {Solution, InputAnswer, PercentageBar},
    computed: {
        ...mapGetters('Quiz', [
            'currentQuestion',
            'currentQuestionAnswer'
        ])
    }
}
</script>

<style scoped lang="less">
.question-wrapper {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    .question {
        .title {
            font-size: 22px;
            span {
                font-weight: bold;
                font-size: larger;
            }
        }
    }
    .input {
        margin: 20px;
        margin-bottom: 40px;
    }
}

.answer {
    padding: 10px;
}
.correct {
    padding: 10px;
}

.info {
    padding: 10px;
}
</style>