export default {
    en: {
        continent: {
            Africa: 'Africa',
            Asia: 'Asia',
            Europe: 'Europe',
            "North America": 'North America',
            Oceania: 'Ozeanien',
            "South America": 'South America'
        },
        country: {
            "AFG": {"name": "Afghanistan", "capital": "Kabul"},
            "ALB": {"name": "Albania", "capital": "Tirana"},
            "ALG": {"name": "Algeria", "capital": "Algiers"},
            "AND": {"name": "Andorra", "capital": "Andorra la Vella"},
            "ANG": {"name": "Angola", "capital": "Luanda"},
            "ANT": {"name": "Antigua and Barbuda", "capital": "Saint John's"},
            "ARG": {"name": "Argentina", "capital": "Buenos Aires"},
            "ARM": {"name": "Armenia", "capital": "Yerevan"},
            "ARU": {"name": "Aruba", "capital": "Oranjestad"},
            "ASA": {"name": "American Samoa", "capital": "Pago Pago"},
            "AUS": {"name": "Australia", "capital": "Canberra"},
            "AUT": {"name": "Austria", "capital": "Vienna"},
            "AZE": {"name": "Azerbaijan", "capital": "Baku"},
            "BAH": {"name": "Bahamas", "capital": "Nassau"},
            "BAN": {"name": "Bangladesh", "capital": "Dhaka"},
            "BAR": {"name": "Barbados", "capital": "Bridgetown"},
            "BDI": {"name": "Burundi", "capital": "Gitega"},
            "BEL": {"name": "Belgium", "capital": "Brussels"},
            "BEN": {"name": "Benin", "capital": "Porto-Novo"},
            "BER": {"name": "Bermuda", "capital": "Hamilton"},
            "BHU": {"name": "Bhutan", "capital": "Thimphu"},
            "BIH": {"name": "Bosnia and Herzegovina", "capital": "Sarajevo"},
            "BIZ": {"name": "Belize", "capital": "Belmopan"},
            "BLR": {"name": "Belarus", "capital": "Minsk"},
            "BOL": {"name": "Bolivia", "capital": "Sucre"},
            "BOT": {"name": "Botswana", "capital": "Gaborone"},
            "BRA": {"name": "Brazil", "capital": "Brasília"},
            "BRN": {"name": "Bahrain", "capital": "Manama"},
            "BRU": {"name": "Brunei", "capital": "Bandar Seri Begawan"},
            "BUL": {"name": "Bulgaria", "capital": "Sofia"},
            "BUR": {"name": "Burkina Faso", "capital": "Ouagadougou"},
            "CAF": {"name": "Central African Republic", "capital": "Bangui"},
            "CAM": {"name": "Cambodia", "capital": "Phnom Penh"},
            "CAN": {"name": "Canada", "capital": "Ottawa"},
            "CAY": {"name": "Cayman Islands", "capital": "George Town"},
            "CGO": {"name": "Republic of the Congo", "capital": "Brazzaville"},
            "CHA": {"name": "Chad", "capital": "N'Djamena"},
            "CHI": {"name": "Chile", "capital": "Santiago"},
            "CHN": {"name": "China", "capital": "Beijing"},
            "CIV": {"name": "Ivory Coast", "capital": "Yamoussoukro"},
            "CMR": {"name": "Cameroon", "capital": "Yaoundé"},
            "COD": {"name": "DR Congo", "capital": "Kinshasa"},
            "COK": {"name": "Cook Islands", "capital": "Avarua"},
            "COL": {"name": "Colombia", "capital": "Bogotá"},
            "COM": {"name": "Comoros", "capital": "Moroni"},
            "CPV": {"name": "Cape Verde", "capital": "Praia"},
            "CRC": {"name": "Costa Rica", "capital": "San José"},
            "CRO": {"name": "Croatia", "capital": "Zagreb"},
            "CUB": {"name": "Cuba", "capital": "Havana"},
            "CYP": {"name": "Cyprus", "capital": "Nicosia"},
            "CZE": {"name": "Czechia", "capital": "Prague"},
            "DEN": {"name": "Denmark", "capital": "Copenhagen"},
            "DJI": {"name": "Djibouti", "capital": "Djibouti"},
            "DMA": {"name": "Dominica", "capital": "Roseau"},
            "DOM": {"name": "Dominican Republic", "capital": "Santo Domingo"},
            "ECU": {"name": "Ecuador", "capital": "Quito"},
            "EGY": {"name": "Egypt", "capital": "Cairo"},
            "ERI": {"name": "Eritrea", "capital": "Asmara"},
            "ESA": {"name": "El Salvador", "capital": "San Salvador"},
            "ESP": {"name": "Spain", "capital": "Madrid"},
            "EST": {"name": "Estonia", "capital": "Tallinn"},
            "ETH": {"name": "Ethiopia", "capital": "Addis Ababa"},
            "FIJ": {"name": "Fiji", "capital": "Suva"},
            "FIN": {"name": "Finland", "capital": "Helsinki"},
            "FRA": {"name": "France", "capital": "Paris"},
            "FSM": {"name": "Micronesia", "capital": "Palikir"},
            "GAB": {"name": "Gabon", "capital": "Libreville"},
            "GAM": {"name": "Gambia", "capital": "Banjul"},
            "GBR": {"name": "United Kingdom", "capital": "London"},
            "GBS": {"name": "Guinea-Bissau", "capital": "Bissau"},
            "GEO": {"name": "Georgia", "capital": "Tbilisi"},
            "GEQ": {"name": "Equatorial Guinea", "capital": "Malabo"},
            "GER": {"name": "Germany", "capital": "Berlin"},
            "GHA": {"name": "Ghana", "capital": "Accra"},
            "GRE": {"name": "Greece", "capital": "Athens"},
            "GRN": {"name": "Grenada", "capital": "St. George's"},
            "GUA": {"name": "Guatemala", "capital": "Guatemala City"},
            "GUI": {"name": "Guinea", "capital": "Conakry"},
            "GUM": {"name": "Guam", "capital": "Hagåtña"},
            "GUY": {"name": "Guyana", "capital": "Georgetown"},
            "HAI": {"name": "Haiti", "capital": "Port-au-Prince"},
            "HKG": {"name": "Hong Kong", "capital": "City of Victoria"},
            "HON": {"name": "Honduras", "capital": "Tegucigalpa"},
            "HUN": {"name": "Hungary", "capital": "Budapest"},
            "INA": {"name": "Indonesia", "capital": "Jakarta"},
            "IND": {"name": "India", "capital": "New Delhi"},
            "IRI": {"name": "Iran", "capital": "Tehran"},
            "IRL": {"name": "Ireland", "capital": "Dublin"},
            "IRQ": {"name": "Iraq", "capital": "Baghdad"},
            "ISL": {"name": "Iceland", "capital": "Reykjavik"},
            "ISR": {"name": "Israel", "capital": "Jerusalem"},
            "ISV": {"name": "United States Virgin Islands", "capital": "Charlotte Amalie"},
            "ITA": {"name": "Italy", "capital": "Rome"},
            "IVB": {"name": "British Virgin Islands", "capital": "Road Town"},
            "JAM": {"name": "Jamaica", "capital": "Kingston"},
            "JOR": {"name": "Jordan", "capital": "Amman"},
            "JPN": {"name": "Japan", "capital": "Tokyo"},
            "KAZ": {"name": "Kazakhstan", "capital": "Nur-Sultan"},
            "KEN": {"name": "Kenya", "capital": "Nairobi"},
            "KGZ": {"name": "Kyrgyzstan", "capital": "Bishkek"},
            "KIR": {"name": "Kiribati", "capital": "South Tarawa"},
            "KOR": {"name": "South Korea", "capital": "Seoul"},
            "KOS": {"name": "Kosovo", "capital": "Pristina"},
            "KSA": {"name": "Saudi Arabia", "capital": "Riyadh"},
            "KUW": {"name": "Kuwait", "capital": "Kuwait City"},
            "LAO": {"name": "Laos", "capital": "Vientiane"},
            "LAT": {"name": "Latvia", "capital": "Riga"},
            "LBA": {"name": "Libya", "capital": "Tripoli"},
            "LBN": {"name": "Lebanon", "capital": "Beirut"},
            "LBR": {"name": "Liberia", "capital": "Monrovia"},
            "LCA": {"name": "Saint Lucia", "capital": "Castries"},
            "LES": {"name": "Lesotho", "capital": "Maseru"},
            "LIE": {"name": "Liechtenstein", "capital": "Vaduz"},
            "LTU": {"name": "Lithuania", "capital": "Vilnius"},
            "LUX": {"name": "Luxembourg", "capital": "Luxembourg"},
            "MAD": {"name": "Madagascar", "capital": "Antananarivo"},
            "MAR": {"name": "Morocco", "capital": "Rabat"},
            "MAS": {"name": "Malaysia", "capital": "Kuala Lumpur"},
            "MAW": {"name": "Malawi", "capital": "Lilongwe"},
            "MDA": {"name": "Moldova", "capital": "Chișinău"},
            "MDV": {"name": "Maldives", "capital": "Malé"},
            "MEX": {"name": "Mexico", "capital": "Mexico City"},
            "MGL": {"name": "Mongolia", "capital": "Ulan Bator"},
            "MHL": {"name": "Marshall Islands", "capital": "Majuro"},
            "MKD": {"name": "North Macedonia", "capital": "Skopje"},
            "MLI": {"name": "Mali", "capital": "Bamako"},
            "MLT": {"name": "Malta", "capital": "Valletta"},
            "MNE": {"name": "Montenegro", "capital": "Podgorica"},
            "MON": {"name": "Monaco", "capital": "Monaco"},
            "MOZ": {"name": "Mozambique", "capital": "Maputo"},
            "MRI": {"name": "Mauritius", "capital": "Port Louis"},
            "MTN": {"name": "Mauritania", "capital": "Nouakchott"},
            "MYA": {"name": "Myanmar", "capital": "Naypyidaw"},
            "NAM": {"name": "Namibia", "capital": "Windhoek"},
            "NCA": {"name": "Nicaragua", "capital": "Managua"},
            "NED": {"name": "Netherlands", "capital": "Amsterdam"},
            "NEP": {"name": "Nepal", "capital": "Kathmandu"},
            "NGR": {"name": "Nigeria", "capital": "Abuja"},
            "NIG": {"name": "Niger", "capital": "Niamey"},
            "NOR": {"name": "Norway", "capital": "Oslo"},
            "NRU": {"name": "Nauru", "capital": "Yaren"},
            "NZL": {"name": "New Zealand", "capital": "Wellington"},
            "OMA": {"name": "Oman", "capital": "Muscat"},
            "PAK": {"name": "Pakistan", "capital": "Islamabad"},
            "PAN": {"name": "Panama", "capital": "Panama City"},
            "PAR": {"name": "Paraguay", "capital": "Asunción"},
            "PER": {"name": "Peru", "capital": "Lima"},
            "PHI": {"name": "Philippines", "capital": "Manila"},
            "PLE": {"name": "Palestine", "capital": "Ramallah"},
            "PLW": {"name": "Palau", "capital": "Ngerulmud"},
            "PNG": {"name": "Papua New Guinea", "capital": "Port Moresby"},
            "POL": {"name": "Poland", "capital": "Warsaw"},
            "POR": {"name": "Portugal", "capital": "Lisbon"},
            "PRK": {"name": "North Korea", "capital": "Pyongyang"},
            "PUR": {"name": "Puerto Rico", "capital": "San Juan"},
            "QAT": {"name": "Qatar", "capital": "Doha"},
            "ROU": {"name": "Romania", "capital": "Bucharest"},
            "RSA": {"name": "South Africa", "capital": "Cape Town"},
            "RUS": {"name": "Russia", "capital": "Moscow"},
            "RWA": {"name": "Rwanda", "capital": "Kigali"},
            "SAM": {"name": "Samoa", "capital": "Apia"},
            "SEN": {"name": "Senegal", "capital": "Dakar"},
            "SEY": {"name": "Seychelles", "capital": "Victoria"},
            "SGP": {"name": "Singapore", "capital": "Singapore"},
            "SKN": {"name": "Saint Kitts and Nevis", "capital": "Basseterre"},
            "SLE": {"name": "Sierra Leone", "capital": "Freetown"},
            "SLO": {"name": "Slovenia", "capital": "Ljubljana"},
            "SMR": {"name": "San Marino", "capital": "San Marino"},
            "SOL": {"name": "Solomon Islands", "capital": "Honiara"},
            "SOM": {"name": "Somalia", "capital": "Mogadishu"},
            "SRB": {"name": "Serbia", "capital": "Belgrade"},
            "SRI": {"name": "Sri Lanka", "capital": "Sri Jayawardenepura Kotte"},
            "SSD": {"name": "South Sudan", "capital": "Juba"},
            "STP": {"name": "São Tomé and Príncipe", "capital": "São Tomé"},
            "SUD": {"name": "Sudan", "capital": "Khartoum"},
            "SUI": {"name": "Switzerland", "capital": "Bern"},
            "SUR": {"name": "Suriname", "capital": "Paramaribo"},
            "SVK": {"name": "Slovakia", "capital": "Bratislava"},
            "SWE": {"name": "Sweden", "capital": "Stockholm"},
            "SWZ": {"name": "Eswatini", "capital": "Mbabane"},
            "SYR": {"name": "Syria", "capital": "Damascus"},
            "TAN": {"name": "Tanzania", "capital": "Dodoma"},
            "TGA": {"name": "Tonga", "capital": "Nuku'alofa"},
            "THA": {"name": "Thailand", "capital": "Bangkok"},
            "TJK": {"name": "Tajikistan", "capital": "Dushanbe"},
            "TKM": {"name": "Turkmenistan", "capital": "Ashgabat"},
            "TLS": {"name": "Timor-Leste", "capital": "Dili"},
            "TOG": {"name": "Togo", "capital": "Lomé"},
            "TPE": {"name": "Taiwan", "capital": "Taipei"},
            "TTO": {"name": "Trinidad and Tobago", "capital": "Port of Spain"},
            "TUN": {"name": "Tunisia", "capital": "Tunis"},
            "TUR": {"name": "Turkey", "capital": "Ankara"},
            "TUV": {"name": "Tuvalu", "capital": "Funafuti"},
            "UAE": {"name": "United Arab Emirates", "capital": "Abu Dhabi"},
            "UGA": {"name": "Uganda", "capital": "Kampala"},
            "UKR": {"name": "Ukraine", "capital": "Kyiv"},
            "URU": {"name": "Uruguay", "capital": "Montevideo"},
            "USA": {"name": "United States", "capital": "Washington DC"},
            "UZB": {"name": "Uzbekistan", "capital": "Tashkent"},
            "VAN": {"name": "Vanuatu", "capital": "Port Vila"},
            "VEN": {"name": "Venezuela", "capital": "Caracas"},
            "VIE": {"name": "Vietnam", "capital": "Hanoi"},
            "VIN": {"name": "Saint Vincent and the Grenadines", "capital": "Kingstown"},
            "YEM": {"name": "Yemen", "capital": "Sana'a"},
            "ZAM": {"name": "Zambia", "capital": "Lusaka"},
            "ZIM": {"name": "Zimbabwe", "capital": "Harare"}
        },
        language: {
            english: "english",
            german: "german"
        },
        main: {
            backToStart: 'Back to Start',
            more: 'More',
            submit: 'Submit',
        },
        question: {
            capitalByCountry: 'What is the Capital of',
            countryByCapital: 'In which country is',
            countryByFlag: 'What country has this flag',
            continentByCountry: 'On which continent is',
            flagByCountry: 'Which flag has this country'
        },
        solution: {
            correctAnswer: 'Correct Answer',
            finalResult: 'Final Result',
            nextQuestion: 'Next Question',
            yourAnswer: 'Your Answer'
        },
        settings: {
            title: 'Settings',
            continents: {
                title: 'Continents'
            },
            language: {
                title: 'Language',
                select: {
                    label: 'Select language ...',
                    german: 'German',
                    english: 'English'
                }
            },
            questionCount: {
                title: 'How many questions?',
                label: 'Questions',
                button: 'All available'
            },
            questionsType: {
                title: 'Type of questions',
                select: {
                    label: 'Select type ...',
                    selection: 'Selection',
                    inputField: 'Input field'
                },
                selection: {
                    label: 'Selection Options'
                }
            },
            quizModus: {
                title: 'Quiz modus',
                select: {
                    label: 'Choose modus ...',
                    whatIsTheCapitalOf: 'What is the Capital of ... ?',
                    inWhichCountryIs: 'In which country is ... ?',
                    inWhichContinentIs: 'On which continent is ... ?',
                    whatCountryHasFlag: 'What country has this flag ... ?',
                    whichFlagBelongsToCountry: 'Which flag belongs to this country ... ?'
                }
            },
            startQuiz: {
                button: 'Start Quiz'
            }
        }
    },
    de: {
        continent: {
            Africa: 'Afrika',
            Asia: 'Asien',
            Europe: 'Europa',
            "North America": 'Nordamerika',
            Oceania: 'Ozeanien',
            "South America": 'Südamerika'
        },
        country: {
            "AFG": {"name": "Afghanistan", "capital": "Kabul"},
            "ALB": {"name": "Albanien", "capital": "Tirana"},
            "ALG": {"name": "Algerien", "capital": "Algiers"},
            "AND": {"name": "Andorra", "capital": "Andorra la Vella"},
            "ANG": {"name": "Angola", "capital": "Luanda"},
            "ANT": {"name": "Antigua und Barbuda", "capital": "Saint John's"},
            "ARG": {"name": "Argentinien", "capital": "Buenos Aires"},
            "ARM": {"name": "Armenien", "capital": "Yerevan"},
            "ARU": {"name": "Aruba", "capital": "Oranjestad"},
            "ASA": {"name": "Amerikanisch-Samoa", "capital": "Pago Pago"},
            "AUS": {"name": "Australien", "capital": "Canberra"},
            "AUT": {"name": "Österreich", "capital": "Wien"},
            "AZE": {"name": "Aserbaidschan", "capital": "Baku"},
            "BAH": {"name": "Bahamas", "capital": "Nassau"},
            "BAN": {"name": "Bangladesch", "capital": "Dhaka"},
            "BAR": {"name": "Barbados", "capital": "Bridgetown"},
            "BDI": {"name": "Burundi", "capital": "Gitega"},
            "BEL": {"name": "Belgien", "capital": "Brussels"},
            "BEN": {"name": "Benin", "capital": "Porto-Novo"},
            "BER": {"name": "Bermuda", "capital": "Hamilton"},
            "BHU": {"name": "Bhutan", "capital": "Thimphu"},
            "BIH": {"name": "Bosnien und Herzegowina", "capital": "Sarajevo"},
            "BIZ": {"name": "Belize", "capital": "Belmopan"},
            "BLR": {"name": "Weißrussland", "capital": "Minsk"},
            "BOL": {"name": "Bolivien", "capital": "Sucre"},
            "BOT": {"name": "Botswana", "capital": "Gaborone"},
            "BRA": {"name": "Brasilien", "capital": "Brasília"},
            "BRN": {"name": "Bahrain", "capital": "Manama"},
            "BRU": {"name": "Brunei", "capital": "Bandar Seri Begawan"},
            "BUL": {"name": "Bulgarien", "capital": "Sofia"},
            "BUR": {"name": "Burkina Faso", "capital": "Ouagadougou"},
            "CAF": {"name": "Zentralafrikanische Republik", "capital": "Bangui"},
            "CAM": {"name": "Kambodscha", "capital": "Phnom Penh"},
            "CAN": {"name": "Kanada", "capital": "Ottawa"},
            "CAY": {"name": "Kaimaninseln", "capital": "George Town"},
            "CGO": {"name": "Kongo", "capital": "Brazzaville"},
            "CHA": {"name": "Tschad", "capital": "N'Djamena"},
            "CHI": {"name": "Chile", "capital": "Santiago"},
            "CHN": {"name": "China", "capital": "Peking"},
            "CIV": {"name": "Elfenbeinküste", "capital": "Yamoussoukro"},
            "CMR": {"name": "Kamerun", "capital": "Yaoundé"},
            "COD": {"name": "Kongo (Dem. Rep.)", "capital": "Kinshasa"},
            "COK": {"name": "Cookinseln", "capital": "Avarua"},
            "COL": {"name": "Kolumbien", "capital": "Bogotá"},
            "COM": {"name": "Komoren", "capital": "Moroni"},
            "CPV": {"name": "Kap Verde", "capital": "Praia"},
            "CRC": {"name": "Costa Rica", "capital": "San José"},
            "CRO": {"name": "Kroatien", "capital": "Zagreb"},
            "CUB": {"name": "Kuba", "capital": "Havana"},
            "CYP": {"name": "Zypern", "capital": "Nikosia"},
            "CZE": {"name": "Tschechien", "capital": "Prag"},
            "DEN": {"name": "Dänemark", "capital": "Kopenhagen"},
            "DJI": {"name": "Dschibuti", "capital": "Dschibuti"},
            "DMA": {"name": "Dominica", "capital": "Roseau"},
            "DOM": {"name": "Dominikanische Republik", "capital": "Santo Domingo"},
            "ECU": {"name": "Ecuador", "capital": "Quito"},
            "EGY": {"name": "Ägypten", "capital": "Kairo"},
            "ERI": {"name": "Eritrea", "capital": "Asmara"},
            "ESA": {"name": "El Salvador", "capital": "San Salvador"},
            "ESP": {"name": "Spanien", "capital": "Madrid"},
            "EST": {"name": "Estland", "capital": "Tallinn"},
            "ETH": {"name": "Äthiopien", "capital": "Addis Ababa"},
            "FIJ": {"name": "Fidschi", "capital": "Suva"},
            "FIN": {"name": "Finnland", "capital": "Helsinki"},
            "FRA": {"name": "Frankreich", "capital": "Paris"},
            "FSM": {"name": "Mikronesien", "capital": "Palikir"},
            "GAB": {"name": "Gabun", "capital": "Libreville"},
            "GAM": {"name": "Gambia", "capital": "Banjul"},
            "GBR": {"name": "Vereinigtes Königreich", "capital": "London"},
            "GBS": {"name": "Guinea-Bissau", "capital": "Bissau"},
            "GEO": {"name": "Georgien", "capital": "Tiflis"},
            "GEQ": {"name": "Äquatorialguinea", "capital": "Malabo"},
            "GER": {"name": "Deutschland", "capital": "Berlin"},
            "GHA": {"name": "Ghana", "capital": "Accra"},
            "GRE": {"name": "Griechenland", "capital": "Athen"},
            "GRN": {"name": "Grenada", "capital": "St. George's"},
            "GUA": {"name": "Guatemala", "capital": "Guatemala City"},
            "GUI": {"name": "Guinea", "capital": "Conakry"},
            "GUM": {"name": "Guam", "capital": "Hagåtña"},
            "GUY": {"name": "Guyana", "capital": "Georgetown"},
            "HAI": {"name": "Haiti", "capital": "Port-au-Prince"},
            "HKG": {"name": "Hongkong", "capital": "City of Victoria"},
            "HON": {"name": "Honduras", "capital": "Tegucigalpa"},
            "HUN": {"name": "Ungarn", "capital": "Budapest"},
            "INA": {"name": "Indonesien", "capital": "Jakarta"},
            "IND": {"name": "Indien", "capital": "Neu Delhi"},
            "IRI": {"name": "Iran", "capital": "Tehran"},
            "IRL": {"name": "Irland", "capital": "Dublin"},
            "IRQ": {"name": "Irak", "capital": "Baghdad"},
            "ISL": {"name": "Island", "capital": "Reykjavik"},
            "ISR": {"name": "Israel", "capital": "Jerusalem"},
            "ISV": {"name": "Amerikanische Jungferninseln", "capital": "Charlotte Amalie"},
            "ITA": {"name": "Italien", "capital": "Rom"},
            "IVB": {"name": "Britische Jungferninseln", "capital": "Road Town"},
            "JAM": {"name": "Jamaika", "capital": "Kingston"},
            "JOR": {"name": "Jordanien", "capital": "Amman"},
            "JPN": {"name": "Japan", "capital": "Tokio"},
            "KAZ": {"name": "Kasachstan", "capital": "Nur-Sultan"},
            "KEN": {"name": "Kenia", "capital": "Nairobi"},
            "KGZ": {"name": "Kirgisistan", "capital": "Bishkek"},
            "KIR": {"name": "Kiribati", "capital": "South Tarawa"},
            "KOR": {"name": "Südkorea", "capital": "Seoul"},
            "KOS": {"name": "Kosovo", "capital": "Pristina"},
            "KSA": {"name": "Saudi-Arabien", "capital": "Riyadh"},
            "KUW": {"name": "Kuwait", "capital": "Kuwait City"},
            "LAO": {"name": "Laos", "capital": "Vientiane"},
            "LAT": {"name": "Lettland", "capital": "Riga"},
            "LBA": {"name": "Libyen", "capital": "Tripoli"},
            "LBN": {"name": "Libanon", "capital": "Beirut"},
            "LBR": {"name": "Liberia", "capital": "Monrovia"},
            "LCA": {"name": "St. Lucia", "capital": "Castries"},
            "LES": {"name": "Lesotho", "capital": "Maseru"},
            "LIE": {"name": "Liechtenstein", "capital": "Vaduz"},
            "LTU": {"name": "Litauen", "capital": "Vilnius"},
            "LUX": {"name": "Luxemburg", "capital": "Luxemburg"},
            "MAD": {"name": "Madagaskar", "capital": "Antananarivo"},
            "MAR": {"name": "Marokko", "capital": "Rabat"},
            "MAS": {"name": "Malaysia", "capital": "Kuala Lumpur"},
            "MAW": {"name": "Malawi", "capital": "Lilongwe"},
            "MDA": {"name": "Moldawien", "capital": "Chișinău"},
            "MDV": {"name": "Malediven", "capital": "Malé"},
            "MEX": {"name": "Mexiko", "capital": "Mexico City"},
            "MGL": {"name": "Mongolei", "capital": "Ulan Bator"},
            "MHL": {"name": "Marshallinseln", "capital": "Majuro"},
            "MKD": {"name": "Nordmazedonien", "capital": "Skopje"},
            "MLI": {"name": "Mali", "capital": "Bamako"},
            "MLT": {"name": "Malta", "capital": "Valletta"},
            "MNE": {"name": "Montenegro", "capital": "Podgorica"},
            "MON": {"name": "Monaco", "capital": "Monaco"},
            "MOZ": {"name": "Mosambik", "capital": "Maputo"},
            "MRI": {"name": "Mauritius", "capital": "Port Louis"},
            "MTN": {"name": "Mauretanien", "capital": "Nouakchott"},
            "MYA": {"name": "Myanmar", "capital": "Naypyidaw"},
            "NAM": {"name": "Namibia", "capital": "Windhoek"},
            "NCA": {"name": "Nicaragua", "capital": "Managua"},
            "NED": {"name": "Niederlande", "capital": "Amsterdam"},
            "NEP": {"name": "Nepal", "capital": "Kathmandu"},
            "NGR": {"name": "Nigeria", "capital": "Abuja"},
            "NIG": {"name": "Niger", "capital": "Niamey"},
            "NOR": {"name": "Norwegen", "capital": "Oslo"},
            "NRU": {"name": "Nauru", "capital": "Yaren"},
            "NZL": {"name": "Neuseeland", "capital": "Wellington"},
            "OMA": {"name": "Oman", "capital": "Muscat"},
            "PAK": {"name": "Pakistan", "capital": "Islamabad"},
            "PAN": {"name": "Panama", "capital": "Panama City"},
            "PAR": {"name": "Paraguay", "capital": "Asunción"},
            "PER": {"name": "Peru", "capital": "Lima"},
            "PHI": {"name": "Philippinen", "capital": "Manila"},
            "PLE": {"name": "Palästina", "capital": "Ramallah"},
            "PLW": {"name": "Palau", "capital": "Ngerulmud"},
            "PNG": {"name": "Papua-Neuguinea", "capital": "Port Moresby"},
            "POL": {"name": "Polen", "capital": "Warschau"},
            "POR": {"name": "Portugal", "capital": "Lissabon"},
            "PRK": {"name": "Nordkorea", "capital": "Pyongyang"},
            "PUR": {"name": "Puerto Rico", "capital": "San Juan"},
            "QAT": {"name": "Katar", "capital": "Doha"},
            "ROU": {"name": "Rumänien", "capital": "Bukarest"},
            "RSA": {"name": "Südafrika", "capital": "Kapstadt"},
            "RUS": {"name": "Russland", "capital": "Moskau"},
            "RWA": {"name": "Ruanda", "capital": "Kigali"},
            "SAM": {"name": "Samoa", "capital": "Apia"},
            "SEN": {"name": "Senegal", "capital": "Dakar"},
            "SEY": {"name": "Seychellen", "capital": "Victoria"},
            "SGP": {"name": "Singapur", "capital": "Singapur"},
            "SKN": {"name": "St. Kitts und Nevis", "capital": "Basseterre"},
            "SLE": {"name": "Sierra Leone", "capital": "Freetown"},
            "SLO": {"name": "Slowenien", "capital": "Ljubljana"},
            "SMR": {"name": "San Marino", "capital": "San Marino"},
            "SOL": {"name": "Salomonen", "capital": "Honiara"},
            "SOM": {"name": "Somalia", "capital": "Mogadishu"},
            "SRB": {"name": "Serbien", "capital": "Belgrad"},
            "SRI": {"name": "Sri Lanka", "capital": "Sri Jayawardenepura Kotte"},
            "SSD": {"name": "Südsudan", "capital": "Juba"},
            "STP": {"name": "São Tomé und Príncipe", "capital": "São Tomé"},
            "SUD": {"name": "Sudan", "capital": "Khartoum"},
            "SUI": {"name": "Schweiz", "capital": "Bern"},
            "SUR": {"name": "Suriname", "capital": "Paramaribo"},
            "SVK": {"name": "Slowakei", "capital": "Bratislava"},
            "SWE": {"name": "Schweden", "capital": "Stockholm"},
            "SWZ": {"name": "Swasiland", "capital": "Mbabane"},
            "SYR": {"name": "Syrien", "capital": "Damaskus"},
            "TAN": {"name": "Tansania", "capital": "Dodoma"},
            "TGA": {"name": "Tonga", "capital": "Nuku'alofa"},
            "THA": {"name": "Thailand", "capital": "Bangkok"},
            "TJK": {"name": "Tadschikistan", "capital": "Dushanbe"},
            "TKM": {"name": "Turkmenistan", "capital": "Ashgabat"},
            "TLS": {"name": "Osttimor", "capital": "Dili"},
            "TOG": {"name": "Togo", "capital": "Lomé"},
            "TPE": {"name": "Taiwan", "capital": "Taipei"},
            "TTO": {"name": "Trinidad und Tobago", "capital": "Port of Spain"},
            "TUN": {"name": "Tunesien", "capital": "Tunis"},
            "TUR": {"name": "Türkei", "capital": "Ankara"},
            "TUV": {"name": "Tuvalu", "capital": "Funafuti"},
            "UAE": {"name": "Vereinigte Arabische Emirate", "capital": "Abu Dhabi"},
            "UGA": {"name": "Uganda", "capital": "Kampala"},
            "UKR": {"name": "Ukraine", "capital": "Kiev"},
            "URU": {"name": "Uruguay", "capital": "Montevideo"},
            "USA": {"name": "Vereinigte Staaten", "capital": "Washington DC"},
            "UZB": {"name": "Usbekistan", "capital": "Tashkent"},
            "VAN": {"name": "Vanuatu", "capital": "Port Vila"},
            "VEN": {"name": "Venezuela", "capital": "Caracas"},
            "VIE": {"name": "Vietnam", "capital": "Hanoi"},
            "VIN": {"name": "St. Vincent und die Grenadinen", "capital": "Kingstown"},
            "YEM": {"name": "Jemen", "capital": "Sana'a"},
            "ZAM": {"name": "Sambia", "capital": "Lusaka"},
            "ZIM": {"name": "Simbabwe", "capital": "Harare"}
        },
        language: {
            english: "englisch",
            german: "deutsch"
        },
        main: {
            backToStart: 'Zurück zum Start',
            more: 'Mehr',
            submit: 'Absenden',
            yourAnswer: 'Deine Antwort'
        },
        question: {
            capitalByCountry: 'Was ist die Hauptstadt von',
            countryByCapital: 'In welchem Land liegt',
            countryByFlag: 'Welches Land hat diese Flagge',
            continentByCountry: 'Auf welchem Kontinent liegt',
            flagByCountry: 'Welche Flagge hat das Land'
        },
        solution: {
            correctAnswer: 'Korrekte Antwort',
            finalResult: 'Endergebnis',
            nextQuestion: 'Nächste Frage',
            yourAnswer: 'Deine Antwort'
        },
        settings: {
            title: 'Einstellungen',
            continents: {
                title: 'Kontinente'
            },
            language: {
                title: 'Sprache',
                select: {
                    label: 'Wähle eine Sprache ...',
                    german: 'Deutsch',
                    english: 'Englisch'
                }
            },
            questionCount: {
                title: 'Wieviele Fragen?',
                label: 'Fragen',
                button: 'Alle verfügbaren'
            },
            questionsType: {
                title: 'Art der Fragen',
                select: {
                    label: 'Wähle eine Art ...',
                    selection: 'Auswahl',
                    inputField: 'Eingabefeld'
                },
                selection: {
                    label: 'Auswahlmöglichkeiten'
                }
            },
            quizModus: {
                title: 'Quiz Modus',
                select: {
                    label: 'Wähle einen Modus ...',
                    whatIsTheCapitalOf: 'Was ist die Hauptstadt von ... ?',
                    inWhichCountryIs: 'In welchem Land liegt ... ?',
                    inWhichContinentIs: 'Auf welchem Kontinent liegt ... ?',
                    whatCountryHasFlag: 'Welches Land hat diese Flagge ... ?',
                    whichFlagBelongsToCountry: 'Welche Flagge gehört zu diesem Land ... ?'
                }
            },
            startQuiz: {
                button: 'Starte Quiz'
            }
        }
    }
}