<template>
        <button class="btn" data-bs-toggle="offcanvas" href="#countryDataOffCanvas" @click="setCountryData(country)">
            <img :src="country.flags.svg">&nbsp;&nbsp;{{ $t("main.more") }} <i class="bi bi-info-circle"></i>
        </button>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: 'CountryDataButton',
    props: {
        country: Object
    },
    methods: {
        ...mapMutations('Quiz', ['setCountryData'])
    }
}
</script>

<style scoped lang="less">
    button {
        cursor: pointer;
        text-decoration: none;
        background-color: #fff;
        color: #000;
        border: 1px solid #ccc;
        border-radius: 0 0 20px 20px;
        img {
            height: 20px;
            border: 1px solid #000;
        }
        &:visited, &:hover, &:active {
            border: 1px solid #ccc;
            background-color: #eee;
            text-decoration: none;
        }

    }
</style>