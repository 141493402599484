<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card shadow-lg">
                    <div class="card-header">
                        {{ $t("settings.title") }}
                    </div>
                    <div class="card-body">
                        <div class="row" data-masonry='{"percentPosition": true }'>
                            <div class="col-lg-4 mb-3">
                                <QuizModus/>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <Language/>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <SelectContinents/>
                            </div>
                            <div class="col-lg-4 mb-3" v-if="showQuestionsType">
                                <QuestionsType/>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <SelectQuestionCount/>
                            </div>
                        </div>

                        <start-button/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Masonry from "masonry-layout";
import Language from "@/components/Settings/Language";

import SelectContinents from "@/components/Settings/SelectContinents";
import SelectQuestionCount from "@/components/Settings/SelectQuestionCount";
import StartButton from "@/components/Settings/StartButton";
import QuestionsType from "@/components/Settings/QuestionsType";
import {mapState} from "vuex";
import QuizModus from "@/components/Settings/QuizModus";

export default {
    name: 'Settings',
    components: {QuizModus, QuestionsType, Language, StartButton, SelectQuestionCount, SelectContinents},
    mounted() {
        this.setDataMasonry();
    },
    watch: {
        quizModus() {
            this.setDataMasonry();
        }
    },
    computed: {
        ...mapState('Settings', ['quizModus', 'questionsType']),
        showQuestionsType() {
            if (this.quizModus === 'continentByCountry' || this.quizModus === 'flagByCountry') {
                return false;
            }

            return true;
        }
    },
    methods: {
        setDataMasonry() {
            setTimeout(() => {
                new Masonry(document.querySelector("[data-masonry]"), {
                    percentPosition: true,
                });
            }, 100);
        }
    }
}
</script>