<template>
    <div class="container">
        <div class="row">
            <div class="col col-lg-4 offset-lg-4">
                <div class="card shadow-lg">
                    <div class="card-header">
                        Geographical Master
                    </div>

                    <CognitoSetNewPassword v-if="errorNewPasswordRequiredAttributes !== false"/>
                    <CognitoLogin v-else />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import CognitoLogin from "@/components/Cognito/Login";
import CognitoSetNewPassword from "@/components/Cognito/SetNewPassword";

export default {
    name: "Cognito",
    components: {CognitoSetNewPassword, CognitoLogin},
    computed: {
        ...mapState('Authentication', ['errorNewPasswordRequiredAttributes'])
    },
    methods: {
        ...mapActions('Authentication', ['setNewPassword'])
    }
};
</script>