<template>
    <div class="card">
        <div class="card-header inverse">
            {{ $t("settings.quizModus.title") }}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <div class="form-floating">
                        <select class="form-control" v-model="modus">
                            <option value="capitalByCountry">{{ $t("settings.quizModus.select.whatIsTheCapitalOf") }}</option>
                            <option value="countryByCapital">{{ $t("settings.quizModus.select.inWhichCountryIs") }}</option>
                            <option value="continentByCountry">{{ $t("settings.quizModus.select.inWhichContinentIs") }}</option>
                            <option value="countryByFlag">{{ $t("settings.quizModus.select.whatCountryHasFlag") }}</option>
                            <option value="flagByCountry">{{ $t("settings.quizModus.select.whichFlagBelongsToCountry") }}</option>
                        </select>
                        <label for="floatingSelect">{{ $t("settings.quizModus.select.label") }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: 'QuizModus',
    data() {
        return {
            modus: null
        }
    },
    watch: {
        modus(modus) {
            this.setQuizModus(modus);
        }
    },
    computed: {
        ...mapState('Settings', ['quizModus'])
    },
    mounted() {
        this.modus = this.quizModus;
    },
    methods: {
        ...mapMutations('Settings', ['setQuizModus'])
    }
}
</script>