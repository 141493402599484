<template>
    <div class="card">
        <div class="card-header inverse">
            {{ $tc('settings.language.title') }}
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <div class="form-floating">
                        <select class="form-control" v-model="$i18n.locale">
                            <option value="de">{{ $t("settings.language.select.german") }}</option>
                            <option value="en">{{ $t("settings.language.select.english") }}</option>
                        </select>
                        <label for="floatingSelect">{{ $t("settings.language.select.label") }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>