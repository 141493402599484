import {createStore} from 'vuex'
import Authentication from '@/store/modules/auth';
import Quiz from '@/store/modules/quiz';
import Settings from '@/store/modules/settings';
import Translations from '@/store/modules/translations';

export default createStore({
    modules: {
        Authentication,
        Quiz,
        Settings,
        Translations
    }
})
