<template>
    <div class="card shadow-lg">
        <div class="card-header inverse">
            <small>{{ askingWords }}</small>
            <br>
            <span v-if="quizModus === 'countryByFlag'"><img :src="question.flags.svg"></span>
            <span v-else>{{ askingQuestionTranslated }}</span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <country-data-button :country="question"/>
                </div>
            </div>

            <div class="row answer">
                <div class="col-5">
                    <div>
                        <small>{{ $t("solution.yourAnswer") }}:</small>
                    </div>
                    <div>
                        <strong :class="question.answerCorrect ? 'text-success' : 'text-danger'">
                            &nbsp;
                            <template v-if="quizModus === 'flagByCountry'">
                                <img :src="question.answer">
                            </template>
                            <template v-else>
                                {{ question.answer }}
                            </template>
                        </strong>
                    </div>
                </div>
                <div class="col-2 align-self-end">
                    <span class="percentage" :class="question.answerCorrect ? 'text-bg-success' : 'text-bg-danger'">
                        <i v-if="question.answerCorrect" class="bi bi-check"></i>
                        <i v-else-if="!question.answerCorrect" class="bi bi-x"></i>
                    </span>
                </div>
                <div class="col-5">
                    <div>
                        <small>{{ $t("solution.correctAnswer") }}:</small>
                    </div>
                    <div>
                        <strong>
                            <i class="bi bi-check-circle"></i> 
                            <template v-if="quizModus === 'flagByCountry'">
                                <img :src="getTranslatedData(question, quizModus)">
                            </template>
                            <template v-else>
                                {{ getTranslatedData(question, quizModus) }}
                            </template>
                        </strong>
                    </div>
                </div>
            </div>

            <div class="row mt-4" v-if="showNextButton">
                <div class="col">
                    <button class="btn btn-secondary" id="nextQuestion" @click="nextQuestion" @keyup.enter="nextQuestion">
                        <strong v-if="lastQuestion">{{ $t("solution.finalResult") }}</strong>
                        <strong v-else>{{ $t("solution.nextQuestion") }}</strong>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import PercentageBar from "@/components/Plugin/PercentageBar";
import ShowResultButtonGroup from "@/components/Results/ShowResultButtonGroup";
import CountryDataButton from "@/components/Plugin/CountryDataButton";

import TranslationsMixin from '@/mixins/translations';

export default {
    name: 'Solution',
    props: {
        question: Object,
        showNextButton: Boolean
    },
    components: {CountryDataButton, ShowResultButtonGroup, PercentageBar},
    mixins: [TranslationsMixin],
    data() {
        return {
            answer: '',
            submitted: false
        }
    },
    mounted() {
        if (this.showNextButton) {
            document.getElementById('nextQuestion').focus();
        }
    },
    computed: {
        ...mapState('Settings', ['quizModus']),
        ...mapGetters('Quiz', ['lastQuestion']),
        answerCorrect() {
            return this.currentQuestion.Country.answerCorrect;
        }
    },
    methods: {
        ...mapMutations('Quiz', ['setNextQuestion']),
        nextQuestion() {
            this.setNextQuestion();
            this.submitted = false;
            this.answer = '';
        },
    }
}
</script>

<style scoped lang="less">
.container {
    margin-top: 0;
}

.percentage {
    padding: 5px;
    border-radius: 50px;
    font-size: larger;
}

.btn-group {
    button {
        font-weight: bold;
    }
}

.card-header {
    img {
        margin-top: 10px;
        width: 70px;
        border: 1px solid #000;
    }
}
.card-body {
    padding-top: 0;
    .answer {
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: larger;
    }
    img {
        margin-left: 5px;
        width: 70px;
        border: 1px solid #000;
    }
    .next-question {
        border-radius: 10px 0 0 10px;
    }
}

</style>