<template>
    <div class="container">
        <div class="row mb-3">
            <div class="col">
                <percentage-bar/>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col">
                <show-result-button-group/>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-xs-12 col-lg-6 mb-4" v-for="question in showResults">
                <solution :question="question" :showNextButton="false"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import PercentageBar from "@/components/Plugin/PercentageBar";
import ShowResultButtonGroup from "@/components/Results/ShowResultButtonGroup";
import CountryDataButton from "@/components/Plugin/CountryDataButton";
import Solution from "@/components/Question/Solution";

export default {
    name: 'Results',
    components: {Solution, CountryDataButton, ShowResultButtonGroup, PercentageBar},
    data() {
        return {
            answer: '',
            submitted: false
        }
    },
    created() {
        //console.log(this.showResults);
    },
    computed: {
        ...mapGetters('Quiz', ['showResults'])
    },
    methods: {
        ...mapMutations('Quiz', ['setCountryData']),
    }
}
</script>

<style scoped lang="less">
.container {
    margin-top: 0;
}

.result {
    position: relative;
    overflow: hidden;
    border: 1px solid #aaa;
    border-radius: 10px;
    .question {
        border-bottom: 1px dashed #aaa;
        .title {
            padding: 5px 0;
            span {
                font-weight: bold;
                font-size: larger;
            }
        }
    }
    .answer {
        padding: 20px 0 60px 0;
        .percentage {
            small {
                padding: 3px 8px;
                border-radius: 10px;
            }
        }
    }
}

.btn-group {
    button {
        font-weight: bold;
    }
}
</style>