import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"

import { createI18n } from 'vue-i18n'
import Translations from './translations'

import StringHelperMixin from '@/mixins/Helper/string';

const messages = Translations

const i18n = createI18n({
    locale: 'de', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

createApp(App)
    .mixin(StringHelperMixin)
    .use(store)
    .use(i18n)
    .mount('#app');
