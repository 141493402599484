<template>
    <div class="card">
        <div class="card-header inverse">
            {{ $t("settings.questionCount.title") }}
        </div>
        <div class="card-body">
            <div class="input-group mb-3">
                <div class="form-floating">
                    <input type="number" class="form-control" :disabled="questionAll ? 'readonly' : false" :value="questionCount" @input="setQuestionCount">
                    <label for="questionCount">{{ $t("settings.questionCount.label") }}</label>
                </div>
                <button class="btn" :class="allCountriesSelected" type="button" id="button-addon1" @click="toggleQuestionAll">{{ $t("settings.questionCount.button") }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: 'SelectQuestionCount',
    computed: {
        ...mapState('Quiz', ['questionCount']),
        ...mapState('Settings', ['questionAll']),
        allCountriesSelected() {
            return this.questionAll ? 'btn-secondary' : 'btn-outline-secondary';
        }
    },
    data() {
        return {
            allCountries: false
        }
    },
    methods: {
        ...mapMutations('Quiz', ['setQuestionCount']),
        ...mapMutations('Settings', ['toggleQuestionAll'])
    }
}
</script>